<template>
	<div>
	  <h1 class="type" v-if="detail.afterStatus === 0">状态 : 待处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 1">状态 : 待处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 2">状态 : 已处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 4">状态 : 已处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 5">状态 : 已处理</h1>
	  <h1 class="type" v-if="detail.afterStatus === 3">状态 : 已取消</h1>
	  <el-card class="box-card">
		<div style="margin-left: 30px">
		  <div class="box"></div>
		  <h2 class="ntext">买家信息</h2>
		</div>
		<div class="text item">
		  <div class="dcenter">
			<div class="main">
			  <div class="shorttext">用户账号</div>
			  <div class="shorttext">用户昵称</div>
			  <div class="shorttext">下单时间</div>
			  <div class="shorttext">支付方式</div>
			</div>
		  </div>
		  <div class="dcenter">
			<div class="main">
			  <div class="longtext" v-if="detail.memberData">{{ detail.memberData.mobile }}</div>
			  <div class="longtext" v-if="detail.memberData">{{ detail.memberData.nickName}}</div>
			  <div class="longtext">{{ detail.createTime }}</div>
			  <div class="longtext" v-if="payType == '0'">{{ "未支付" }}</div>
			  <div class="longtext" v-if="payType == '1'">{{ "白条" }}</div>
			  <div class="longtext" v-if="payType == '2'">{{ "支付宝" }}</div>
			  <div class="longtext" v-if="payType == '3'">{{ "微信" }}</div>
			  <div class="longtext" v-if="payType == '4'">{{ "农行" }}</div>
			</div>
		  </div>
		</div>
		<div style="margin-left: 30px">
		  <div class="box"></div>
		  <h2 class="ntext">申请售后信息</h2>
		</div>
		<div class="text item">
		  <div class="domain">
			<div>
			  <span class="autoheader">售后类型</span>
			  <p>{{ detail.afterType == 1 ? "换货" : "退款" }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">售后编号</span>
			  <p>{{ detail.afterNo }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">关联订单编号</span>
			  <a @click="Jump(detail.orderId)" class="order" href="#">
				{{ $route.query.orderNo }}
			  </a>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">申请退款金额</span>
			  <p>{{ detail.afterAmountStr }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">实际退款</span>
			  <p>{{ detail.afterRealAmountStr }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">售后原因</span>
			  <p>{{ detail.afterReason }}</p>
			</div>
		  </div>
		  <div class="domain">
			<div>
			  <span class="autoheader">申请时间</span>
			  <p>{{ detail.createTime }}</p>
			</div>
		  </div>
		  <div class="domain" v-if="
			  detail.afterStatus != '0' &&
			  detail.afterStatus == '1' &&
			  detail.afterStatus == '3'
			">
			<div>
			  <span class="autoheader">处理时间</span>
			  <p style="color: red">{{ detail.planTime }}</p>
			</div>
		  </div>
		  <div class="domain" v-if="detail.afterStatus == '4'">
			<div>
			  <span class="autoheader">退款方式</span>
			  <p style="color: red">原路返回</p>
			</div>
		  </div>
		  <div class="domain" v-if="detail.afterStatus == '2'">
			<div>
			  <span class="autoheader">驳回原因</span>
			  <p style="color: red">{{ detail.rejectReason }}</p>
			</div>
		  </div>
		  <div class="domain mainfooter">
			<div>
			  <span class="autoheader">申请说明</span>
			  <p class="order">{{ detail.afterExplain }}</p>
			</div>
		  </div>
		  <div class="domain mainfooter">
			<div class="mainfooters">
			  <span class="autoheaders">图片凭证</span>
			  <template v-if="preview">
				<img-preview :src="src" :onClick="closeImgPreview" :onKeydown="keydownCloseImgPreview"></img-preview>
			  </template>
			  <img :src="item" alt="" v-for="(item, index) in afterImageList" :key="index" @click="previewImage(item)" />
			</div>
		  </div>
		</div>
		<div style="margin-left: 30px">
		  <div class="box"></div>
		  <h2 class="ntext">退款商品</h2>
		</div>
		<div class="text item">
		  <div class="dcenter">
			<div class="main">
			  <div class="shorttext">商品图片</div>
			  <div class="shorttext">商品名称</div>
			  <div class="shorttext">规格</div>
			  <div class="shorttext">数量</div>
			  <div class="shorttext">单价</div>
			  <div class="shorttext">小计</div>
			  <div class="shorttext">实付</div>
			  <div class="shorttext">退款金额</div>
			</div>
		  </div>
		  <div class="dcenter" v-for="item in afterGoodsList" :key="item.id">
			<div class="main">
			  <div class="longtext">
				<img :src="item.mainImageUrl" alt="" />
			  </div>
			  <div class="longtext">{{ item.name }}</div>
			  <div class="longtext">{{ item.meterUnit+item.quality+item.basicUnit }}</div>
			  <div class="longtext">{{ item.goodsNum }}</div>
			  <div class="longtext">¥{{ item.priceStr }}</div>
			  <div class="longtext">
				¥{{item.priceStr*item.goodsNum}}
			  </div>
			  <div class="longtext">¥{{ item.priceStr*item.goodsNum }}</div>
			  <div class="longtext">¥{{ item.priceStr*item.goodsNum }}</div>
			</div>
		  </div>
		</div>
		<div class="footdokter">
		  <el-button class="btn" @click="$router.push('/group/after/list')">返回</el-button>
		</div>
	  </el-card>
	</div>
  </template>

  <script>
  import { afterDetail,oredrGetCombineId } from '@/api/group'
	import imgPreview from "./ImageBig";
	export default {
	  data() {
		return {
		  detail: {}, //售后订单详情
		  afterImageList: [], //图片详情
		  preview: false,
		  src: "",
		  afterGoodsList: [], //商品详情
      payType:''
		};
	  },
	  components: {
		"img-preview": imgPreview,
	  },
	  created() {
      afterDetail({id:this.$route.query.id}).then((res) => {
		  this.detail = res.data;
      console.log(this.detail)
		  if(this.detail.afterStatus == '1' || this.detail.afterStatus == '0'){
			 this.detail.afterRealAmountStr = res.data.afterAmountStr
		  }else{
			this.detail.afterRealAmountStr = res.data.afterRealAmountStr
		  }
		  this.payType = res.data.payType;
		  this.afterImageList = res.data.afterServiceImageList;
		  this.afterGoodsList = res.data.brokerOrderGoodsDataList;
		});
	  },
	  methods: {
		previewImage(src) {
		  this.preview = true;
		  this.src = src;
		},
		keydownCloseImgPreview(e) {
		  if (e && e.keyCode == 27) {
			this.preview = false;
		  }
		},
		closeImgPreview() {
		  this.preview = false;
		},
		//跳转
		Jump(val) {
		  oredrGetCombineId({
			orderId: val
		  }).then((res) => {
			this.$router.push({
			  path: "/group/order/getById",
			  query: {
				id: res.data
			  },
			});
		  });
		},

	  },
	};
  </script>

  <style lang='less' scoped>
	.type {
	  font-weight: 700;
	  margin: 20px 0;
	  font-size: 20px;
	  margin-left: 25px;
	}

	.text {
	  font-size: 14px;
	}

	.item {
	  padding: 28px 30px;
	}

	.box-card {
	  width: 100%;
	}

	.box {
	  width: 15px;
	  height: 15px;
	  background-color: #8400ff;
	  display: inline-block;
	}

	.ntext {
	  display: inline-block;
	  margin-left: 5px;
	  font-weight: 700;
	  font-size: 18px;
	}

	.dcenter {
	  width: 100%;
	  border: 1px solid #efefef;

	  .main {
		display: flex;

		.shorttext {
		  border-right: 1px solid #efefef;
		  width: 360px;
		  height: 40px;
		  text-align: center;
		  line-height: 40px;
		  background-color: #f9fafc;
		  font-size: 14px;
		  font-weight: 700;
		}

		.longtext {
		  padding: 5px;
		  overflow: hidden; //超出的文本隐藏
		  text-overflow: ellipsis; //溢出用省略号显示
		  white-space: nowrap; //溢出不换行
		  border-right: 1px solid #efefef;
		  width: 360px;
		  height: 80px;
		  text-align: center;
		  line-height: 80px;
		  font-size: 14px;

		  img {
			width: 80px;
			height: 80px;
		  }
		}
	  }
	}

	.mainfooter {
	  border-bottom: 1px solid #efefef;
	}

	.domain {
	  .order {
		margin-left: 25px;
		color: #0066ff;
	  }

	  //   border: 1px solid #efefef;
	  border-right: 1px solid #efefef;
	  border-left: 1px solid #efefef;
	  border-top: 1px solid #efefef;
	  width: 100%;

	  .autoheader {
		display: inline-block;
		height: 46px;
		width: 160px;
		border-right: 1px solid #efefef;
		background: #f9fafc;
		text-align: center;
		line-height: 46px;
		font-weight: 600;
		color: #333333;
		font-size: 14px;
	  }

	  .autoheaders {
		display: inline-block;
		height: 80px;
		width: 160px;
		border-right: 1px solid #efefef;
		background: #f9fafc;
		text-align: center;
		line-height: 80px;
		font-weight: 600;
		color: #333333;
		font-size: 14px;
	  }

	  img {
		height: 80px;
		width: 80px;
	  }

	  p {
		display: inline-block;
		margin-left: 30px;
	  }

	  input {
		display: inline-block;
		width: 60px;
		height: 24px;
		outline: none;
		border: 1px solid #efefef;
		padding: 5px;
		font-weight: normal;
	  }

	  .mainfooters {
		height: 80px;
		display: flex;
	  }
	}

	.footdokter {
	  display: flex;
	  justify-content: center;

	  .btn {
		width: 100px;
	  }
	}

	.tekst {
	  font-size: 16px;
	  color: #000;
	  margin-top: 15px;
	  margin-left: 30px;
	}
  </style>
